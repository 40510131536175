import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DeckOutlinedIcon from '@mui/icons-material/DeckOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MainModal from "./MainModal";

export default function Callback2() {
  const theme = useTheme();

  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <MainModal active={active} setActive={setActive} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                p: 1,
                m: 1,
                height: 150,
                width: "auto",
                color: theme.palette.secondary.main,
              }}
            >
              <QuizOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
              <DeckOutlinedIcon
                sx={{ alignSelf: "flex-end", fontSize: {xs:50, sm: 70, md: 100, lg: 100} }}
              />
              <Groups2OutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ alignContent: "center", textAlign: "center" }}
          >
            <Typography
              align="center"
              color={theme.palette.secondary.main}
              sx={{
                fontSize: { xs: 25, sm: 35, md: 37, lg: 40 },
                mb: 1,
                fontFamily: "Ink Free",
              }}
            >
              Остались вопросы ?
            </Typography>
            <Typography
              align="center"
              color={theme.palette.text.dark}
              sx={{
                fontSize: { xs: 14, sm: 16, md: 18, lg: 18 },
                mb: 1,
              }}
            >
              оставьте заявку и наш менеджер ответит на все ваши вопросы
            </Typography>
            <ColorButton
              variant="contained"
              size="small"
              sx={{ maxWidth: 200, height: 50, fontSize: 12 }}
              onClick={handleOpen}
            >
              Рассчитать лестницу
            </ColorButton>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                p: 1,
                m: 1,
                height: 150,
                color: theme.palette.secondary.main,
              }}
            >
              <AutoGraphOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
              <QuestionAnswerOutlinedIcon
                sx={{ alignSelf: "flex-end", fontSize: {xs:50, sm: 70, md: 100, lg: 100} }}
              />
              <EmojiObjectsOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
