import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import header from "./img/bg.png";
import logo from "./img/logo.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Button, Container, Stack, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import MainModal from "./MainModal";

export default function Present() {
  const theme = useTheme();

  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  var classes = {
    logoHorizontallyCenter: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${header})`,
        maxHeight: "100%",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "white",
      }}
    >
      <MainModal active={active} setActive={setActive} />
      <Box>
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.5)",
            display: { xs: "flex", md: "flex", lg: "none" },
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
          >
            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 0, ml: 2, color: theme.palette.text, mt: 1.5 }}
              >
                +7(921)772-92-18
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                href="https://wa.me/79217729218?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
                sx={{ color: theme.palette.secondary.main }}
              >
                <WhatsAppIcon fontSize="large" color="white" />
              </IconButton>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <img src={logo} alt="logo" style={{ maxWidth: "100%", height: "auto" }} />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none", lg: "none" } }}>
          <Stack
            spacing={2}
            sx={{ textAlign: "center", mt: 3, alignItems: "center" }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                maxWidth: "100%",
                fontWeight: "bold",
                color: theme.palette.text.main,
              }}
            >
              ПРОИЗВОДИМ И УСТАНАВЛИВАЕМ ЛЕСТНИЦЫ ПО ИНДИВИДУАЛЬНОМУ ПРОЕКТУ В
              МОСКВЕ И ОБЛАСТИ
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                maxWidth: "100%",
                fontSize: 12,
                color: theme.palette.text.dark,
              }}
            >
              *Получите профессиональную консультацию в кратчайшие сроки
            </Typography>
            <ColorButton
              variant="contained"
              size="small"
              sx={{ maxWidth: 200, height: 50, fontSize: 14 }}
              onClick={handleOpen}
            >
              Оставить заявку
            </ColorButton>
            <Box
              sx={{
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: { xs: "flex", md: "none", lg: "none" },
                py: 1,
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.secondary.main,
                  }}
                >
                  11 ЛЕТ ОПЫТА
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 9,
                    color: theme.palette.text.main,
                  }}
                >
                  реализации проектов любой сложности
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.secondary.main,
                  }}
                >
                  ПРОИЗВОДСТВО “ПОД КЛЮЧ”
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 9,
                    color: theme.palette.text.main,
                  }}
                >
                  без переплат и головной боли
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.secondary.main,
                  }}
                >
                  10 ЛЕТ ГАРАНТИИ
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 9,
                    color: theme.palette.text.main,
                  }}
                >
                  на конструкцию и монтаж лестницы
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex", lg: "none" } }}>
          <Stack
            spacing={2}
            sx={{ textAlign: "left", mt: 3, alignItems: "left", width: "100%" }}
          >
            <Container>
              <Stack spacing={3} sx={{my: 10}}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    maxWidth: "80%",
                    fontWeight: "bold",
                    color: theme.palette.text.main,
                  }}
                >
                  ПРОИЗВОДИМ И УСТАНАВЛИВАЕМ ЛЕСТНИЦЫ ПО ИНДИВИДУАЛЬНОМУ ПРОЕКТУ
                  В МОСКВЕ И ОБЛАСТИ
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 12,
                    color: theme.palette.text.dark,
                  }}
                >
                  *Получите профессиональную консультацию в кратчайшие сроки
                </Typography>
                <ColorButton
                  variant="contained"
                  size="small"
                  sx={{ maxWidth: 200, height: 50, fontSize: 14 }}
                  onClick={handleOpen}
                >
                  Оставить заявку
                </ColorButton>
              </Stack>
            </Container>

            <Box
              sx={{
                textAlign: "left",
                justifyContent: "space-evenly",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: { xs: "none", md: "flex", lg: "none" },
                py: 1,
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 15,
                    color: theme.palette.secondary.main,
                  }}
                >
                  11 ЛЕТ ОПЫТА
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.text.main,
                  }}
                >
                  реализации проектов любой сложности
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 15,
                    color: theme.palette.secondary.main,
                  }}
                >
                  ПРОИЗВОДСТВО “ПОД КЛЮЧ”
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.text.main,
                  }}
                >
                  без переплат и головной боли
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 15,
                    color: theme.palette.secondary.main,
                  }}
                >
                  10 ЛЕТ ГАРАНТИИ
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    maxWidth: "100%",
                    fontSize: 10,
                    color: theme.palette.text.main,
                  }}
                >
                  на конструкцию и монтаж лестницы
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>

      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgba(0,0,0,0.5)",
          display: { xs: "none", md: "none", lg: "flex" },
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 0, ml: 2, color: theme.palette.text }}
          >
            +7(921)772-92-18
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 2 }}
          ></Typography>
          <Box style={classes.logoHorizontallyCenter}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              href="/"
              aria-label="menu"
            >
              <img alt="logo" src={logo} />
            </IconButton>
          </Box>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            href="https://wa.me/79217729218?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
            sx={{ color: theme.palette.secondary.main }}
          >
            <WhatsAppIcon fontSize="large" color="white" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container fixed>
        <Box sx={{ my: 15, display: { xs: "none", md: "none", lg: "flex" } }}>
          <Stack spacing={2} sx={{ textAlign: "left" }}>
            <Typography
              variant="h3"
              component="div"
              sx={{
                maxWidth: "100%",
                color: theme.palette.text.main,
              }}
            >
              ПРОИЗВОДИМ И УСТАНАВЛИВАЕМ ЛЕСТНИЦЫ ПО ИНДИВИДУАЛЬНОМУ ПРОЕКТУ В
              МОСКВЕ И ОБЛАСТИ
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                maxWidth: "100%",
                fontSize: 14,
                color: theme.palette.text.dark,
              }}
            >
              *Получите профессиональную консультацию в кратчайшие сроки
            </Typography>
            <ColorButton
              variant="contained"
              size="medium"
              sx={{ maxWidth: 300, height: 75, fontSize: 20 }}
              onClick={handleOpen}
            >
              Оставить заявку
            </ColorButton>
          </Stack>
        </Box>
      </Container>
      <Box
        sx={{
          justifyContent: "space-around",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: { xs: "none", md: "none", lg: "flex" },
          p: 5,
        }}
      >
        <Box>
          <Typography
            variant="body1"
            component="div"
            sx={{
              maxWidth: "100%",
              fontSize: 20,
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
          >
            11 ЛЕТ ОПЫТА
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{
              fontSize: 20,
              color: theme.palette.text.main,
            }}
          >
            реализации проектов любой сложности
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            component="div"
            sx={{
              maxWidth: "100%",
              fontSize: 20,
              color: theme.palette.secondary.main,
            }}
          >
            ПРОИЗВОДСТВО “ПОД КЛЮЧ”
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{
              maxWidth: "100%",
              fontSize: 20,
              color: theme.palette.text.main,
            }}
          >
            без переплат и головной боли
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            component="div"
            sx={{
              maxWidth: "100%",
              fontSize: 20,
              color: theme.palette.secondary.main,
            }}
          >
            10 ЛЕТ ГАРАНТИИ
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{
              maxWidth: "100%",
              fontSize: 20,
              color: theme.palette.text.main,
            }}
          >
            на конструкцию и монтаж лестницы
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
