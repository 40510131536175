import "./App.css";
import {
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Present from "./components/Present";
import About from "./components/About";
import VariantStairs from "./components/VariantStairs";
import Callback from "./components/Callback";
import Callback2 from "./components/Callback2";
import Works from "./components/Works";
import Stepper from "./components/Stepper";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#191E1B",
        light: "#bdd1cc",
      },
      secondary: {
        main: "#C54C3B",
        light: "#f4f6f8",
      },
      text: {
        main: "#EEE8DA",
        dark: "#a39f96"
      },
    },
    typography: {
      fontFamily: "Days One",
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          color: theme.palette.text.main,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Present />
        <About />
        <VariantStairs/>
        <Callback/>
        <Works/>
        <Stepper/>
        <Callback2/>
        <Contacts/>
        <Footer/>
      </Box>
    </ThemeProvider>
  );
}

export default App;
