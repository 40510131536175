import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";

export default function Stepper() {
  const theme = useTheme();
  const customTheme = {
    iconBox: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 5,
      width: { xs: 70, sm: 90, md: 120, lg: 150 },
      height: { xs: 70, sm: 90, md: 120, lg: 150 },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
    },
    icons: {
      fontSize: { xs: 50, sm: 70, md: 90, lg: 120 },
    },
    typographyBox: {
      fontSize: { xs: 15, sm: 15, md: 18, lg: 22 },
    },
    marginBox: {
      mx: { xs: 0, sm: 6, md: 8, lg: 10 },
    },
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Typography
          align="center"
          color={theme.palette.secondary.main}
          sx={{ fontSize: { xs: 25, md: 35, lg: 50 } }}
        >
          Как мы работаем ?
        </Typography>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "top",
            textAlign: "center",
            my: 5,
          }}
        >
          <Grid xs>
            <Box sx={customTheme.marginBox}>
              <Box sx={customTheme.iconBox}>
                <RateReviewOutlinedIcon sx={customTheme.icons} />
              </Box>
              <Typography sx={customTheme.typographyBox}>ЗАЯВКА</Typography>
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={customTheme.marginBox}>
              <Box sx={customTheme.iconBox}>
                <DesignServicesOutlinedIcon sx={customTheme.icons} />
              </Box>
              <Typography sx={customTheme.typographyBox}>ЗАМЕР</Typography>
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={customTheme.marginBox}>
              <Box sx={customTheme.iconBox}>
                <PaidOutlinedIcon sx={customTheme.icons} />
              </Box>
              <Typography sx={customTheme.typographyBox}>
                БРОНЬ МАТЕРИАЛА
              </Typography>
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={customTheme.marginBox}>
              <Box sx={customTheme.iconBox}>
                <EngineeringOutlinedIcon sx={customTheme.icons} />
              </Box>
              <Typography sx={customTheme.typographyBox}>МОНТАЖ</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
