import { useTheme } from "@emotion/react";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import SensorOccupiedOutlinedIcon from "@mui/icons-material/SensorOccupiedOutlined";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";

export default function About() {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Typography
          align="center"
          color={theme.palette.secondary.main}
          sx={{ fontSize: { xs: 25, md: 35, lg: 50 } }}
        >
          Коротко о нас
        </Typography>
        <Container>
          <Typography
            align="center"
            component="div"
            sx={{ fontSize: { xs: 13, md: 18, lg: 24 } }}
          >
            <span style={{ color: theme.palette.secondary.main }}>
              ООО “Тула Лестница”
            </span>{" "}
            - ваш надежный партнёр при изготовлении лестницы
            <span style={{ color: theme.palette.secondary.main }}>
              {" "}
              “под ключ”
            </span>
            . Мы имеем широкий опыт проектирования, производства, монтажа и
            ввода в эксплуатацию лестниц любой сложности по индивидуальным
            проектам.
          </Typography>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "top",
              textAlign: "center",
              my: 5,
            }}
          >
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: 5,
                  width: 120,
                  height: 120,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ThumbUpOffAltOutlinedIcon sx={{ fontSize: 75 }} />
              </Box>
              <Typography sx={{fontSize: { xs: 15, sm: 15, md: 18, lg: 22 }}}>БЕЗУПРЕЧНОЕ КАЧЕСТВО</Typography>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: 5,
                  width: 120,
                  height: 120,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SensorOccupiedOutlinedIcon sx={{ fontSize: 75 }} />
              </Box>
              <Typography sx={{fontSize: { xs: 15, sm: 15, md: 18, lg: 22 }}}>ИНДИВИДУАЛЬНЫЙ ПОДХОД</Typography>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: 5,
                  width: 120,
                  height: 120,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArchitectureOutlinedIcon sx={{ fontSize: 75 }} />
              </Box>
              <Typography sx={{fontSize: { xs: 15, sm: 15, md: 18, lg: 22 }}}>ВЫПОЛНЕНИЕ САМЫХ СЛОЖНЫХ ПРОЕКТОВ</Typography>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: 5,
                  width: 120,
                  height: 120,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HandshakeOutlinedIcon sx={{ fontSize: 75 }} />
              </Box>
              <Typography sx={{fontSize: { xs: 15, sm: 15, md: 18, lg: 22 }}}>МЫ ПРОДАЕМ КОНЕЧНЫЙ ПРОДУКТ</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
