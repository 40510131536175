import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import MainModal from "./MainModal";

export default function Callback() {
  const theme = useTheme();

  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
        <MainModal active={active} setActive={setActive} />
      <Container>
        <Grid container>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                p: 1,
                m: 1,
                height: 150,
                width: "auto",
                color: theme.palette.secondary.main,
              }}
            >
              <ArchitectureOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
              <StairsOutlinedIcon
                sx={{ alignSelf: "flex-end", fontSize: {xs:50, sm: 70, md: 100, lg: 100} }}
              />
              <FunctionsOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ alignContent: "center", textAlign: "center" }}
          >
            <Typography
              align="center"
              color={theme.palette.secondary.main}
              sx={{
                fontSize: { xs: 25, md: 35, lg: 32 },
                mb: 1,
                fontFamily: "Ink Free",
              }}
            >
              Получите предварительный расчет желаемой лестницы
            </Typography>
            <ColorButton
              variant="contained"
              size="small"
              sx={{ maxWidth: 200, height: 50, fontSize: 14 }}
              onClick={handleOpen}
            >
              Рассчитать лестницу
            </ColorButton>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                p: 1,
                m: 1,
                height: 150,
                color: theme.palette.secondary.main,
              }}
            >
              <CalculateOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
              <PercentOutlinedIcon
                sx={{ alignSelf: "flex-end", fontSize: {xs:50, sm: 70, md: 100, lg: 100} }}
              />
              <AutoFixHighOutlinedIcon sx={{ fontSize: {xs:50, sm: 70, md: 100, lg: 100} }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
