import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";

export default function Contacts() {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Container>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            my: 2.5,
          }}
        >
          <Grid xs={12} sm={12} md={6} lg={6}>
            <YMaps>
              <Map
                width="100%"
                defaultState={{
                  center: [54.143248, 37.594495],
                  zoom: 15,
                }}
              >
                <Placemark defaultGeometry={[54.143248, 37.594495]} />
              </Map>
            </YMaps>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6}>
            <Box>
              <Typography
                align="center"
                color={theme.palette.secondary.main}
                sx={{ fontSize: { xs: 18, md: 19, lg: 30 }, mt: { xs: 2 } }}
              >
                Контакты
              </Typography>
              <Typography
                component="div"
                sx={{
                  fontSize: { xs: 14, md: 18, lg: 24 },
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "right",
                    lg: "right",
                  },
                }}
              >
                Адрес: г.Тула, проезд Энергетиков 8<p>+7 (921) 772-92-18</p>
              </Typography>
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                href="https://wa.me/79217729218?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%20%21%20%D0%9C%D0%BD%D0%B5%20%D0%BD%D0%B5%D0%BE%D0%B1%D1%85%D0%BE%D0%B4%D0%B8%D0%BC%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F."
                sx={{ color: theme.palette.secondary.main }}
              >
                <WhatsAppIcon fontSize="large" color="white" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
