import emailjs from "@emailjs/browser";

export default function mail(name, phone) {
  const serviceId = "service_nvn4lyb";
  const templateId = "template_5x7p74c";
  const publicKey = "kala65PAeXf5iOgls";
  const templateMail = {
    name: name,
    phone: phone,
  };

  emailjs
    .send(serviceId, templateId, templateMail, publicKey)
    .then((response) => {
      console.log("Sent", response);
    }).catch((error) => {
        console.error("Error", error);
    });

}
