import { useTheme } from '@emotion/react'
import { Box, Container, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React from 'react';
import i1 from './img/works/1.jpg';
import i2 from './img/works/2.jpg';
import i3 from './img/works/3.jpg';
import i4 from './img/works/4.jpg';
import i5 from './img/works/5.jpg';
import i6 from './img/works/6.jpg';
import i7 from './img/works/7.jpg';
import i8 from './img/works/8.jpg';
import i9 from './img/works/9.jpg';
import i10 from './img/works/10.jpg';

export default function Works() {
    const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box>
        <Typography
          align="center"
          color={theme.palette.secondary.main}
          sx={{ fontSize: { xs: 25, md: 35, lg: 50 }, mb: 3 }}
        >
         Наши работы
        </Typography>
        <Container>
        <ImageList sx={{ width: '100%', height: '100%' }} cols={2} rowHeight='auto'>
        <ImageListItem>
            <img src={i1} alt='1' />
        </ImageListItem>
        <ImageListItem>
            <img src={i2} alt='2' />
        </ImageListItem>
        <ImageListItem>
            <img src={i3} alt='3' />
        </ImageListItem>
        <ImageListItem>
            <img src={i4} alt='4' />
        </ImageListItem>
        <ImageListItem>
            <img src={i5} alt='5' />
        </ImageListItem>
        <ImageListItem>
            <img src={i6} alt='6' />
        </ImageListItem>
        <ImageListItem>
            <img src={i7} alt='7' />
        </ImageListItem>
        <ImageListItem>
            <img src={i8} alt='8' />
        </ImageListItem>
        <ImageListItem>
            <img src={i9} alt='9' />
        </ImageListItem>
        <ImageListItem>
            <img src={i10} alt='10' />
        </ImageListItem>
        </ImageList>
        </Container>
        </Box>
    </Box>
  )
}
