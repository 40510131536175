import { useTheme } from '@emotion/react'
import { Box, Container, Typography } from '@mui/material'
import React from 'react'

export default function Footer() {
    const theme = useTheme();
  return (
    <Box sx={{width: '100%', height: 25, bgcolor: theme.palette.secondary.main, borderRadius: '15px 15px 0px 0px'}}>
        <Container>
            <Typography variant="">Тула Лестница © Все права защищены 2024</Typography>
        </Container>
    </Box>
  )
}
