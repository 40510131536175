import { useTheme } from "@emotion/react";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import mk from "./img/variants/mk.png";
import vint from "./img/variants/vint.png";
import wood from "./img/variants/wood.png";
import console from "./img/variants/console.png";

export default function VariantStairs() {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Typography
          align="center"
          color={theme.palette.secondary.main}
          sx={{ fontSize: { xs: 25, md: 35, lg: 50 }, mb: 7 }}
        >
          Виды лестниц
        </Typography>
        <Container>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              my: 2.5,
            }}
          >
            <Grid xs="lg">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: `-12px 12px 2px 1px ${theme.palette.text.main}`,
                }}
                src={mk}
                alt="Металлокаркас"
              />
            </Grid>
            <Grid xs sx={{ maxWidth: "98%" }}>
              <Box>
                <Typography
                  align="center"
                  color={theme.palette.secondary.main}
                  sx={{ fontSize: { xs: 18, md: 19, lg: 30 }, mt: { xs: 2 } }}
                >
                  Металлокаркас
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: { xs: 14, md: 18, lg: 24 },
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    textAlign: {
                      xs: "center",
                      sm: "right",
                      md: "right",
                      lg: "right",
                    },
                  }}
                >
                  один из классических, эстетичных и надежных вариантов
                  межэтажных конструкций. Открытая, воздушная, современная
                  лестница на монокосоуре из профильной металлической трубы.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              my: 2.5,
            }}
          >
            <Grid xs order={{ xs: 2, sm: 1 }} sx={{ maxWidth: "98%" }}>
              <Box>
                <Typography
                  noWrap
                  align="center"
                  color={theme.palette.secondary.main}
                  sx={{ fontSize: { xs: 18, md: 20, lg: 30 }, mt: { xs: 2 } }}
                >
                  Винтовая лестница
                </Typography>
                <Typography
                  align="left"
                  component="div"
                  sx={{
                    fontSize: { xs: 14, md: 18, lg: 24 },
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    textAlign: {
                      xs: "center",
                      sm: "left",
                      md: "left",
                      lg: "left",
                    },
                  }}
                >
                  является конструктивной альтернативой маршевой лестнице. Она
                  позволяет сэкономить пространство, сохранив свои полезные
                  качества. Винтовая лестница смотрится как воздушная, легкая и
                  прозрачная. Особенности конструкции позволяют вписаться
                  практически в любой интерьер.
                </Typography>
              </Box>
            </Grid>
            <Grid order={{ xs: 1, sm: 1 }} xs="lg">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: `12px 12px 2px 1px ${theme.palette.text.main}`,
                }}
                src={vint}
                alt="Винтовая лестница"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              my: 2.5,
            }}
          >
            <Grid xs="lg">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: `-12px 12px 2px 1px ${theme.palette.text.main}`,
                }}
                src={wood}
                alt="Деревянная лестница"
              />
            </Grid>
            <Grid xs sx={{ maxWidth: "98%" }}>
              <Box>
                <Typography
                  align="center"
                  color={theme.palette.secondary.main}
                  sx={{ fontSize: { xs: 18, md: 19, lg: 30 }, mt: { xs: 2 } }}
                >
                  Деревянная лестница
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    fontSize: { xs: 14, md: 18, lg: 24 },
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    textAlign: {
                      xs: "center",
                      sm: "right",
                      md: "right",
                      lg: "right",
                    },
                  }}
                >
                  деревянные лестницы обладают такими положительными качествами,
                  как прочность и стойкость к нагрузкам, гармония с интерьером
                  помещений, относительная дешевизна и простота в эксплуатации.
                  Массив дерева, используемый в конструировании деревянных
                  лестниц экологически безопасен.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              my: 2.5,
            }}
          >
            <Grid xs order={{ xs: 2, sm: 1 }} sx={{ maxWidth: "98%" }}>
              <Box>
                <Typography
                  noWrap
                  align="center"
                  color={theme.palette.secondary.main}
                  sx={{ fontSize: { xs: 18, md: 20, lg: 30 }, mt: { xs: 2 } }}
                >
                  Консольная лестница
                </Typography>
                <Typography
                  align="left"
                  component="div"
                  sx={{
                    fontSize: { xs: 14, md: 18, lg: 24 },
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    textAlign: {
                      xs: "center",
                      sm: "left",
                      md: "left",
                      lg: "left",
                    },
                  }}
                >
                  лестница подходит любителям современных стилей – минимализма,
                  лофта. Ничего лишнего. Иногда на таких лестницах даже не
                  делают ограждений. Можно сомневаться в безопасности такого
                  решения, но смотрится действительно эффектно. Для удобства
                  подъёма в такой лестнице на стене ставят поручень, за который
                  можно держаться при подъёме.
                </Typography>
              </Box>
            </Grid>
            <Grid order={{ xs: 1, sm: 1 }} xs="lg">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  boxShadow: `12px 12px 2px 1px ${theme.palette.text.main}`,
                }}
                src={console}
                alt="Консольная лестница"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
