import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import mail from "./mail";

import {
  Grid,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useTheme } from "@emotion/react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#191E1B",
  color: "#EEE8DA",
  boxShadow: 24,
  p: 4,
};

const MainModal = ({ active, setActive, children }) => {
  const [open, setOpen] = React.useState(false);
  const [send, setSend] = React.useState(false);
  const handleClose = () => setActive(false);
  const theme = useTheme();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      phone: "",
    },
  });
  const onSubmit = async (data) => {
    mail(data.firstName, data.phone);
    setSend(true);
    await delay(3000)
    handleClose();
    setSend(false);
  };

  return (
    <div>
      <Modal
        open={!active ? open : setOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3} sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: theme.palette.secondary.main }}
              >
                Обратная связь
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Оставьте заявку, она будет обработана в кратчайшие сроки !
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      inputProps={{ style: { color: theme.palette.text.main } }}
                      sx={{
                        "& label.Mui-focused": {
                          color: theme.palette.secondary.main,
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: theme.palette.text.main,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.main,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                          "&:hover fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                        },
                        width: "70%",
                      }}
                      required
                      id="outlined-required"
                      label="Имя"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  rules={{ validate: matchIsValidTel }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      required
                      inputProps={{ style: { color: theme.palette.text.main } }}
                      sx={{
                        "& label.Mui-focused": {
                          color: theme.palette.secondary.main,
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: theme.palette.text.main,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.main,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                          "&:hover fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: theme.palette.text.main,
                          },
                        },
                        width: "70%",
                        my: 2,
                      }}
                      {...field}
                      helperText={
                        fieldState.invalid ? "Некорректный номер телефона" : ""
                      }
                      error={fieldState.invalid}
                      focusOnSelectCountry
                      defaultCountry="RU"
                    />
                  )}
                />
                {send && (
                  <Stack
                    sx={{ ml: 2.5 }}
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <CheckCircleOutlineOutlinedIcon color="success" />
                    <Typography variant="h6" color="green" sx={{ my: 2 }}>
                      Заявка успешно отправлена{" "}
                    </Typography>
                  </Stack>
                )}
                <ColorButton
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{ maxWidth: 250, height: 50, fontSize: 15 }}
                >
                  Оставить заявку
                </ColorButton>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default MainModal;
